/**
 * Add the "is-animated" class on mount to prevent transitions on page load.
 *
 */
export default {
  name: 'init-transition',

  componentDidMount () {
    setTimeout(() => {
      this.el.classList.add('is-animated')
    }, 0)
  },
}
