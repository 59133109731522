/**
 * Toggle a class on a specified DOM node.
 *
 * Props:
 * className(string, required): The toggled class.
 */
export default {
  name: 'toggle-class',

  ui: {
    toggler: '[js-toggle-class-toggler]',
    target: '[js-toggle-class-target]',
  },

  events: [
    {
      on: 'click',
      target: 'toggler',
      handle () {
        this.ui.target.classList.toggle(this.props.className)
      },
    },
  ],
}
