import { get } from '@bigyouth/base'

import { get as getPipe } from '../utils/sigpipe'

import { ACTIONS as MODAL_ACTIONS } from './modal'

/**
 * Can be placed on an element to open a modal when the element is triggered by mouse or keyboard.
 *
 * Props:
 * target(string, required): Query string for the targetted modal
 */
export default {
  name: 'modal-control',

  events: [
    {
      on: 'click',
      handle: 'openModal',
    },
  ],

  openModal () {
    if (!this.pipe) {
      try {
        const modal = get(this.props.target)

        this.pipe = getPipe(modal.getAttribute('data-pipe-id'))
      } catch (e) {
        console.error(
          `[MODAL-CONTROL] Are you sure target ${this.props.target} exists ?`
        )
      }
    }

    if (this.pipe) {
      this.pipe.write({ action: MODAL_ACTIONS.OPEN, emitterEl: this.el })
    }
  },
}
