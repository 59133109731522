// import Sendinblue from 'sendinblue-api'
import { get } from '@bigyouth/base'

export default {
  name: 'form-newsletter',

  ui: {
    emailInput: '[js-form-newsletter-input]',
    submitButton: '[js-form-newsletter-button]',
  },

  events: [
    {
      on: 'submit',
      handle (e) {
        e.preventDefault()

        this.loader.classList.remove('hidden')
        this.confirmationText.classList.add('hidden')
        this.errorText.classList.add('hidden')

        const formData = new FormData(this.el)
        const request = new XMLHttpRequest()

        request.addEventListener('load', t => {
          this.loader.classList.add('hidden')

          try {
            const response = JSON.parse(t.target.response)
            this.ui.emailInput.value = ''
            this.confirmationText.textContent = response.message

            this.confirmationText.classList.remove('hidden')
            this.errorText.classList.add('hidden')
          } catch (e) {
            this.confirmationText.classList.add('hidden')
            this.errorText.classList.remove('hidden')
          }
        })

        request.open('POST', `${this.el.action}?isAjax=1`)
        request.send(formData)
      },
    },
  ],

  componentDidMount () {
    this.loader = get('[js-form-newsletter-loader]')
    this.confirmationText = get('[js-form-newsletter-confirmation]')
    this.errorText = get('[js-form-newsletter-error]')
  },
}
