/**
 * Handle nav bar, especially on mobile.
 */
export default {
  name: 'nav',

  ui: {
    btnBurger: '[js-nav-burger]',
    panel: '[js-nav-panel]',
  },

  events: [
    {
      on: 'click',
      target: 'btnBurger',
      handle () {
        this.ui.btnBurger.classList.toggle('is-open')
        this.ui.panel.classList.toggle('is-open')
      },
    },
  ],
}
